import {
  Box,
  Button,
  Container,
  Divider,
  Fab,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import CarrouselEnterprises from "../components/layout/CarrouselEnterprises";
import Faq from "../components/layout/Faq";
import styles from "./Talents.module.css";

//images
import imgHero from "../imgs/TalentsCover@2x.png";
import imgTalentsFastSign from "../imgs/TalentsFastSign@2x.png";
import imgTouchTech from "../imgs/TouchTech@2x.png";
import imgTestimonialDariana from "../imgs/dariana@2x.png";
import imgTestimonialFelipe from "../imgs/felipe@2x.png";
import imgTestimonialJuliete from "../imgs/juliete@2x.png";

//icons
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";

//GraphQl
import { useQuery } from "@apollo/client";
import { WhatsApp } from "@mui/icons-material";
import { order_by } from "../__generated__/graphql";
import { Loading } from "../components/Loading";
import { GET_SUMMARY_AND_JOBS } from "../graphql/jobs.graphql";

function mountListItem(
  icon: JSX.Element,
  title: string,
  description: string,
  i: string
) {
  return (
    <Grid key={i} container rowGap={2}>
      <Grid item xs={12} sm={12} lg={12}>
        <Grid container columnGap={3} alignItems="center" rowGap={3}>
          <Grid item className={styles.iconBenefit}>
            {icon}
          </Grid>
          <Grid
            item
            lg
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
          >
            <h5 className="disableMarginBlock">{title}</h5>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
          >
            <h3 className="disableMarginBlock">{title}</h3>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        ml={11}
        lg={9}
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <p className="disableMarginBlock">{description}</p>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
      >
        <p className="disableMarginBlock">{description}</p>
      </Grid>
    </Grid>
  );
}

const listItems = [
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: "Plataforma Gratuita",
    description:
      "É isso mesmo a plataforma é gratuita! Queremos te ajudar a encontrar sua oportunidade no mercado e não vamos te cobrar por isso.",
  },
  {
    icon: <StarRoundedIcon />,
    title: "Um único cadastro, muitas oportunidades",
    description:
      "Simples, fácil e rápido. Assim é o cadastro na nossa plataforma. Além disso você faz ele uma única vez e ele serve para todas as oportunidades que aparecerem aqui!",
  },
  {
    icon: <PriorityHighRoundedIcon />,
    title: "Sem vagas absurdas",
    description:
      "A gente também vê aquelas vagas absurdas na internet. Não se preocupe que aqui na Connectabil nós cuidamos para que esse tipo de coisa não aconteça e não desperdice o seu tempo.",
  },
  {
    icon: <PersonSearchRoundedIcon />,
    title: "Encontre vagas e seja encontrado por elas!",
    description:
      "Dizem que quem procura acha. Aqui além de você achar muitas oportunidades elas te acham também. \n\nAlém dos nossos recrutadores buscarem pelo seu perfil, as empresas também fazem busca ativa por talentos.",
  },
];

const faqQuestionAnswers = [
  {
    question: "O cadastro é grátis?",
    answer:
      "Totalmente grátis para os candidatos. Queremos ter cada vez mais pessoas recebendo o seu “SIM”. Nos ajude mandando o site para mais e mais pessoas.",
  },
  {
    question: "O que é fit cultural?",
    answer:
      "É saber se o candidato e a empresa têm os mesmos valores. Queremos que além dos requisitos da vaga, você possa escolher empresas para trabalhar, que tenham os mesmos propósitos que você. É muito importante preencher essa etapa.",
  },
  {
    question: "Quantos testes tenho que fazer?",
    answer:
      "Na maioria das vezes apenas 1 teste. Porém, caso você se candidate para mais de um cargo, será necessário fazer mais de um teste. Não se preocupe, que te avisaremos qual ou quais precisará fazer.",
  },
  {
    question: "Quantas entrevistas tenho que fazer?",
    answer:
      "Pelo seu cadastro no portal, as empresas já podem analisar muitas coisas, como: Fit Cultural, teste técnico e Vídeo currículo. Por isso, quanto mais completo estiver o seu cadastro, mais chances terá de fazer apenas 1 entrevista e já ser aprovado.",
  },
  {
    question: "Como acompanho o andamento das vagas?",
    answer:
      "Assim que estiver logado, na página principal você conseguirá acompanhar o status de todas as vagas que se aplicou. Além disso, para todas as vagas que passar para a primeira fase, você receberá emails constantemente informando qual o status da vaga e como você deve seguir.",
  },
  {
    question: "Quanto tempo demora para eu me recolocar?",
    answer:
      "Depende muito do conhecimento de cada pessoa, mas fique tranquilo que temos vagas de diversos níveis, sempre focado para profissionais que querem trabalhar em contabilidade ou na área financeira. E pra te dar uma mãozinha, disponibilizamos cursos gratuitos para melhorarem os seus conhecimentos na área.",
  },
];

function Talents() {
  const [active, setActive] = useState("testimonial1");
  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.up("sm"));

  function mountTestimonial(
    photo: JSX.Element,
    author: string,
    text: string,
    i: string
  ) {
    if (screen) {
      return (
        <Grid key={"testimonial" + i} item lg>
          <Grid
            container
            className={
              active === "testimonial" + i
                ? `${styles.activeTestimonial}`
                : `${styles.testimonial}`
            }
            onMouseEnter={() => setActive("testimonial" + i)}
          >
            <Grid container item textAlign="center" py={5} px={5}>
              <p>{text}</p>
            </Grid>
            <Grid item lg={12}>
              <Divider />
            </Grid>
            <Grid
              container
              item
              lg={12}
              py={3}
              paddingLeft={5}
              columnGap={2}
              alignItems="center"
            >
              <Grid item>{photo}</Grid>
              <Grid item>
                <h6>{author}</h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid key={"testimonial" + i} item lg>
          <Grid container className={styles.testimonialMobile}>
            <Grid container item textAlign="center" py={5} px={5}>
              <p>{text}</p>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Divider />
            </Grid>
            <Grid
              container
              item
              lg={12}
              py={3}
              paddingLeft={5}
              columnGap={2}
              alignItems="center"
            >
              <Grid item>{photo}</Grid>
              <Grid item>
                <h6>{author}</h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  const testimonials = [
    {
      photo: <img src={imgTestimonialJuliete} alt="Juliete Rolim" />,
      author: "Juliete Rolim",
      text: "Adorei o processo! Foi muito rápido e objetivo conquistar o meu emprego pelo portal da Connectabil. Estou muito feliz!",
    },
    {
      photo: <img src={imgTestimonialDariana} alt="Dariana" />,
      author: "Dariana",
      text: "Eu amei a plataforma, cadastrei meu currículo, fiz algumas entrevistas e, quando vi, recebi a tão esperada oferta de trabalho. Valeu, Connectabil!",
    },
    {
      photo: <img src={imgTestimonialFelipe} alt="Felipe Bicalho" />,
      author: "Felipe Bicalho",
      text: "Estou muito feliz por ter conquistado meu emprego com a ajuda da Connectabil. O processo foi bem claro e pude dar o meu melhor. Recomendo!",
    },
  ];

  const { data, loading } = useQuery(GET_SUMMARY_AND_JOBS, {
    variables: {
      orderBy: [
        {
          date_published: order_by.desc,
        },
      ],
      limit: 5,
    },
  });

  const summary = data?.customer_vacancy_jobs_aggregate.aggregate;

  return (
    <Container maxWidth={false} disableGutters={true}>
      <Fab
        variant="extended"
        color="primary"
        href="https://wa.me/+5511961381052"
        sx={{
          position: "fixed",
          bottom: { xs: "80px", sm: "80px", md: "56px" },
          right: { xs: "16px", sm: "16px", md: "136px" },
          color: "#fff",
          textTransform: "none",
          fontWeight: "bold",
          fontFamily: "Open Sans",
        }}
      >
        <WhatsApp sx={{ mr: 1 }} />
        Precisa de ajuda com o cadastro?
      </Fab>
      <Container maxWidth={false} sx={{ backgroundColor: "#FAFAFA" }}>
        <Container>
          <Grid
            container
            paddingTop={{ xs: 10, sm: 10, md: 20 }}
            paddingBottom={10}
          >
            <Grid
              container
              item
              paddingBottom={10}
              columnSpacing={3}
              flexDirection={{ xs: "row", sm: "row", md: "row-reverse" }}
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                lg
                sx={{
                  aspectRatio: { xs: "1/1", sm: "1/1", md: "inherit" },
                  backgroundImage: `url(${imgHero})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundOrigin: "content-box",
                }}
              ></Grid>
              <Grid container item lg={6} rowGap={7}>
                <Grid item>
                  <h1 className="disableMarginBlock">
                    Centenas de vagas contábeis e financeiras. <br />
                    <span className={styles.greenText}>100% grátis</span>
                  </h1>
                </Grid>
                <Grid container item columnGap={3} rowGap={3} lg={12}>
                  <Grid item xs={12} sm={12} lg>
                    <Link href="/vagas" underline="none">
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth={true}
                      >
                        Confira Vagas
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} lg>
                    <Link
                      href={`${process.env.REACT_APP_CANDIDATE_URL}`}
                      underline="none"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                      >
                        Cadastre-se Grátis
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container>
        <CarrouselEnterprises />
        <Grid container py={10} justifyContent="center" rowGap={7}>
          <Grid container item xs={12} sm={12} lg={12}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={5}
              rowGap={{ xs: 6, sm: 6, md: 0 }}
              flexDirection={{ md: "column" }}
            >
              <Grid
                container
                item
                lg={12}
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  flexBasis: { md: "20% !important" },
                }}
              >
                <h3 className="disableMarginBlock">
                  Muitas oportunidades e um{" "}
                  <span className={styles.greenText}>
                    cadastro único e rapidinho
                  </span>
                  .
                </h3>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
              >
                <h2 className="disableMarginBlock">
                  Muitas oportunidades e um{" "}
                  <span className={styles.greenText}>
                    cadastro único e rapidinho
                  </span>
                  .
                </h2>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                lg={12}
                sx={{
                  aspectRatio: "1/1",
                  backgroundImage: `url(${imgTalentsFastSign})`,
                  backgroundSize: { xs: "100%", sm: "100%", md: "contain" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundOrigin: "content-box",
                  flexBasis: { md: "80% !important" },
                }}
              ></Grid>
            </Grid>
            <Grid item lg={1}></Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={6}
              rowGap={8}
              paddingTop={{ xs: 10, sm: 10, md: 0 }}
            >
              {listItems.map((e, i) => {
                return mountListItem(e.icon, e.title, e.description, String(i));
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Link
              href={`${process.env.REACT_APP_CANDIDATE_URL}`}
              underline="none"
            >
              <Button variant="contained" color="primary" fullWidth={true}>
                Cadastre-se Grátis
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} className={styles.backgroundRadialPurple}>
        <Container maxWidth="lg" disableGutters={true}>
          <Grid container py={10} spacing={3}>
            <Grid
              item
              lg={7}
              position="relative"
              paddingBottom={{ xs: 32, sm: 32, md: 0 }}
            >
              <h2 className={styles.titlePerfectMatch}>
                Sua próxima oportunidade está a poucos cliques de você
              </h2>
              <Box className={styles.bgImgPerfectMatch}>
                <img
                  src={imgTouchTech}
                  alt="Mãos desenhadas apenas com linhas"
                />
              </Box>
            </Grid>

            <Grid item lg={5}>
              <Grid container py={5} px={{ xs: 0, sm: 0, md: 4 }}>
                <Grid
                  container
                  item
                  lg={12}
                  className={styles.matchDataDisplay}
                >
                  <Grid container item>
                    <Grid
                      container
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      zIndex="2"
                      justifyContent={"center"}
                    >
                      <h2 className="disableMarginBlock">
                        {loading && <Loading />} {!loading && summary?.count}
                      </h2>
                    </Grid>
                    <Grid
                      container
                      item
                      lg={12}
                      zIndex="2"
                      justifyContent={"center"}
                      sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                    >
                      <h6 className="disableMarginBlock">Vagas Abertas</h6>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      md={12}
                      zIndex="2"
                      justifyContent={"center"}
                      sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                    >
                      <h5 className="disableMarginBlock">Vagas Abertas</h5>
                    </Grid>
                  </Grid>
                  <Box className={styles.backgroundStripes}></Box>
                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  className={styles.matchDataDisplay}
                  mt={6}
                >
                  <Grid container item>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      zIndex="2"
                      justifyContent={"center"}
                    >
                      <h2 className="disableMarginBlock">+6.100</h2>
                    </Grid>
                    <Grid
                      container
                      item
                      lg={12}
                      justifyContent={"center"}
                      zIndex="2"
                      sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                    >
                      <h6 className="disableMarginBlock">
                        Contratações Intermediadas
                      </h6>
                    </Grid>

                    <Grid
                      container
                      item
                      lg={12}
                      zIndex="2"
                      textAlign={"center"}
                      justifyContent={"center"}
                      sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                    >
                      <h5 className="disableMarginBlock">
                        Contratações Intermediadas
                      </h5>
                    </Grid>
                  </Grid>
                  <Box className={styles.backgroundStripes}></Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container maxWidth={false} sx={{ backgroundColor: "#fafafa" }}>
        <Container>
          <Grid container py={10} rowGap={7}>
            <Grid
              item
              lg={12}
              justifyContent={"center"}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <h5 className={`disableMarginBlock ${styles.titleTestimonials}`}>
                Conheça a história de quem já encontrou o seu SIM!
              </h5>
            </Grid>

            <Grid
              item
              lg={12}
              sx={{
                textAlign: "center",
                display: { xs: "flex", sm: "flex", md: "none" },
              }}
            >
              <h3 className={`disableMarginBlock ${styles.titleTestimonials}`}>
                Conheça a história de quem já encontrou o seu SIM!
              </h3>
            </Grid>

            <Grid container item columnGap={3} rowGap={6} alignItems="center">
              {testimonials.map((e, i) => {
                return mountTestimonial(e.photo, e.author, e.text, String(i));
              })}
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container>
        <Faq
          questionsAnswers={faqQuestionAnswers}
          color="primary"
          btnText={"Cadastre-se Grátis"}
          btnLink={`${process.env.REACT_APP_CANDIDATE_URL}`}
        />
      </Container>
      <Fab />
    </Container>
  );
}

export default Talents;
