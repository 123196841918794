// components and styles
import { Box, Button, Container, Fab, Grid } from "@mui/material";
import CarrouselEnterprises from "../components/layout/CarrouselEnterprises";
import styles from "./Enterprises.module.css";

// icons
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import SyncProblemRoundedIcon from "@mui/icons-material/SyncProblemRounded";
import ViewKanbanRoundedIcon from "@mui/icons-material/ViewKanbanRounded";

// images
import { WebRounded, WhatsApp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import imgConstruidaParaVoce from "../imgs/ConstruidaParaVoce@2x.png";
import imgEnterpriseHero from "../imgs/EmpresasCover@2x.png";
import imgFiltrosAvançados from "../imgs/FiltrosAvançados@2x.png";
import imgHandsTouching from "../imgs/HandsTouching@2x.png";
import imgMelhoresTalentos from "../imgs/MelhoresTalentos@2x.png";

function Enterprises() {
  function mountHeroList(text) {
    return (
      <Grid
        container
        item
        p={3}
        alignItems="center"
        className={styles.hero_item}
        columnGap={2}
      >
        <Grid item className={styles.hero_badge}>
          <DoneRoundedIcon
            sx={{
              height: { xs: "16px", sm: "16px", md: "24px" },
              width: { xs: "16px", sm: "16px", md: "24px" },
            }}
          />
        </Grid>
        <Grid item xs sm lg>
          <p className="disableMarginBlock">{text}</p>
        </Grid>
      </Grid>
    );
  }

  const heroList = [
    "Mais de 165 mil currículos aguardam a sua vaga",
    "Faça processos seletivos mais rápidos e assertivos",
    "Talentos com testes técnicos e fit cultural",
  ];

  function mountBenefitItemList(icon, title, description) {
    return (
      <Grid container item xs={12} sm={12} lg={6}>
        <Grid container item xs={12} sm={12} lg={12} rowGap={2}>
          <Grid container columnGap={3} alignItems="center" rowGap={3}>
            <Grid item className={styles.iconBenefit}>
              {icon}
            </Grid>
            <Grid item sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
              <h5 className="disableMarginBlock">{title}</h5>
            </Grid>

            <Grid item sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
              <h3 className="disableMarginBlock">{title}</h3>
            </Grid>
          </Grid>

          <Grid item ml={{ xs: 0, sm: 0, md: 11 }} xs={12} md={12} lg={9}>
            <p>{description}</p>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const benefitsItemList = [
    {
      icon: <BadgeRoundedIcon />,
      title: "Contrate em até 14 dias",
      description:
        "Torne seus processos seletivos mais rápidos e assertivos seja com a nossa assessoria ou com você tendo nas suas mãos todo o poder da nossa plataforma.",
    },
    {
      icon: <PsychologyRoundedIcon />,
      title: "Veja testes técnicos e fit cultural",
      description:
        "Avalie melhor os talentos com base em testes técnicos específicos para áreas que você deseja contratar e o fit cultural para entender como o perfil dele pode se alinhar com o que sua empresa precisa.",
    },
    {
      icon: <ViewKanbanRoundedIcon />,
      title: "Gerencie tudo em um só lugar",
      description:
        "Ter tudo em um único lugar facilita muito a nossa vida, certo? Imagina então com seus processos seletivos. Você pode verificar o desempenho de talentos dentro de suas vagas anteriores e acelerar suas contratações.",
    },
    {
      icon: <SyncProblemRoundedIcon />,
      title: "Diminua a rotativadade na equipe",
      description:
        "Ter a contratação feita de forma assertiva, considerando capacidade técnica e fit cultural, trará resultados financeiros ainda melhores para sua empresa. A contratação correta traz colaboradores mais motivados, com entregas melhores e com plano de carreiras a longo prazo.",
    },
  ];

  function mountListAdvancedFilters(img, imgAlt, title, desc) {
    return (
      <Grid container item lg justifyContent="center" rowGap={3}>
        <Grid item>
          <img width="280" src={img} alt={imgAlt} />
        </Grid>
        <Grid container item rowGap={2}>
          <Grid item>
            <h5 className="disableMarginBlock">{title}</h5>
          </Grid>
          <Grid item>
            <p className="disableMarginBlock">{desc}</p>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const listAdvacedFilters = [
    {
      img: imgFiltrosAvançados,
      imgAlt: "Busca com Filtros Avançados",
      title: "Faça buscas rápidas com Filtros Avançados",
      desc: "Encontre o talento que se encaixa exatamente com aquilo que você procura para a sua empresa.",
    },
    {
      img: imgMelhoresTalentos,
      imgAlt: "Banco de dados de talentos do mercado contábil e financeiro",
      title: "Os melhores talentos da área contábil e financeira",
      desc: "Não perca mais tempo procurando por talentos em bancos poluídos, tenha um banco totalmente focado na sua área.",
    },
    {
      img: imgConstruidaParaVoce,
      imgAlt: "Nossa plataforma foi construída para você",
      title: "Nossa plataforma foi construída para você",
      desc: "Desenvolvemos produtos e planos pensando nas diversas fases, tamanhos e necessidades da sua empresa.",
    },
  ];

  function handleHeroButton() {
    const elem = document.getElementById("products");
    const elemRect = elem.getBoundingClientRect();
    const top = elemRect.top;
    document.documentElement.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }

  return (
    <Container maxWidth={false} disableGutters={true}>
      <Fab
        variant="extended"
        color="primary"
        href="https://wa.me/+5511961381052"
        sx={{
          position: "fixed",
          bottom: { xs: "80px", sm: "80px", md: "56px" },
          right: { xs: "16px", sm: "16px", md: "136px" },
          color: "#fff",
          textTransform: "none",
          fontWeight: "bold",
          fontFamily: "Open Sans",
        }}
      >
        <WhatsApp sx={{ mr: 1 }} />
        Fale com um consultor
      </Fab>
      <Container>
        {/* Hero Section */}
        <Grid
          container
          columnSpacing={3}
          paddingTop={{ xs: 10, sm: 10, md: 20 }}
          paddingBottom={10}
          flexDirection={{ xs: "row", sm: "row", md: "row-reverse" }}
        >
          {/* Right Content Image */}
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            sx={{
              aspectRatio: { xs: "1/1", sm: "1/1", md: "inherit" },
              backgroundImage: `url(${imgEnterpriseHero})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundOrigin: "content-box",
            }}
          ></Grid>

          {/* Left Content */}
          <Grid container item xs={12} sm={12} lg={6} rowGap={12}>
            <Grid container item rowGap={5}>
              <Grid item>
                <h1 className="disableMarginBlock">
                  A{" "}
                  <span className={styles.hero_highlight}>
                    maior base de currículos
                  </span>{" "}
                  contábeis e financeiros
                </h1>
              </Grid>
              <Grid container item rowGap={3}>
                {heroList.map((item) => {
                  return mountHeroList(item);
                })}
              </Grid>
            </Grid>

            <Grid container item>
              <Button
                fullWidth={true}
                color="secondary"
                variant="contained"
                size="large"
                onClick={handleHeroButton}
              >
                Contrate Agora
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* End of Hero Section */}
        <CarrouselEnterprises />

        <Grid container columnGap={6} py={10}>
          {listAdvacedFilters.map((e) => {
            return mountListAdvancedFilters(e.img, e.imgAlt, e.title, e.desc);
          })}
        </Grid>
      </Container>

      {/* Section Hands touching */}
      <Container
        maxWidth={false}
        className={styles.backgroundRadialPurple}
        disableGutters={true}
      >
        {/* Hands for Mobile (avoid gutters cutting them) */}
        <Box
          className={styles.bgImgPerfectMatch}
          sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
        >
          <img src={imgHandsTouching} alt="Mãos desenhadas apenas com linhas" />
        </Box>

        <Container>
          <Grid container py={10} columnGap={3}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={6}
              position="relative"
              paddingBottom={{ xs: 32, sm: 32, md: 0 }}
            >
              <h2 className={`${styles.titlePerfectMatch} disableMarginBlock`}>
                Sua empresa e nossos talentos são o match perfeito
              </h2>
              <Box
                className={styles.bgImgPerfectMatch}
                sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
              >
                <img
                  src={imgHandsTouching}
                  alt="Mãos desenhadas apenas com linhas"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} lg={5}>
              <Grid container py={{ xs: 0, sm: 0, md: 2 }} rowGap={6}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  className={styles.matchDataDisplay}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    zIndex="2"
                    justifyContent={"center"}
                    textAlign={"center"}
                  >
                    <h2 className="disableMarginBlock">+165.000</h2>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    zIndex="2"
                    sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                    justifyContent={"center"}
                  >
                    <h6 className="disableMarginBlock">
                      Currículos de Talentos
                    </h6>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    zIndex="2"
                    sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                    justifyContent={"center"}
                  >
                    <h5 className="disableMarginBlock">
                      Currículos de Talentos
                    </h5>
                  </Grid>
                  <Box className={styles.backgroundStripes}></Box>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  className={styles.matchDataDisplay}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    zIndex="2"
                    justifyContent={"center"}
                    textAlign={"center"}
                  >
                    <h2 className="disableMarginBlock">+6.100</h2>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    zIndex="2"
                    sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                    justifyContent={"center"}
                  >
                    <h6 className="disableMarginBlock">
                      Contratações Intermediadas
                    </h6>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    zIndex="2"
                    sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                    justifyContent={"center"}
                    textAlign={"center"}
                  >
                    <h5 className="disableMarginBlock">
                      Contratações Intermediadas
                    </h5>
                  </Grid>
                  <Box className={styles.backgroundStripes}></Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
      {/* End of Section Hands Touching */}

      {/* Section Benefits */}
      <Container>
        <Grid container py={10} columnSpacing={8} rowSpacing={10}>
          {benefitsItemList.map((e) => {
            return mountBenefitItemList(e.icon, e.title, e.description);
          })}
        </Grid>
      </Container>
      {/* End of Section Benefits */}

      {/* Section Produtcs */}
      <Container
        maxWidth={false}
        disableGutters={true}
        className={styles.backgroundRadialPurple}
        id="products"
      >
        <Container>
          {/* Products */}
          <Grid container py={10} rowGap={5}>
            <Grid item lg={12}>
              <Grid
                container
                item
                rowGap={2}
                className={styles.descriptionProductSection}
              >
                <Grid
                  item
                  lg={8}
                  sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                >
                  <h3 className="disableMarginBlock">
                    Independente do seu tamanho, a gente tem uma solução
                  </h3>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                >
                  <h2 className="disableMarginBlock">
                    Independente do seu tamanho, a gente tem uma solução
                  </h2>
                </Grid>

                <Grid item lg={8}>
                  <p className="disableMarginBlock">
                    Empresa é como a nossa vida, cheia de fases. Pensamos em uma
                    solução para a sua empresa em cada fase, tamanho e
                    necessidade.
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12}>
              <Grid container gap={3}>
                {/* First Product Top Left  */}
                <Grid item lg className={styles.productWhiteDisplay}>
                  <Grid container item rowGap={5} py={5} px={3}>
                    {/* Header Content */}
                    <Grid container item xs={12} sm={12} lg={12} zIndex="2">
                      <Grid
                        container
                        rowGap={2}
                        className={styles.productHeaderContent}
                      >
                        <Grid
                          item
                          sx={{
                            display: { xs: "none", sm: "none", md: "flex" },
                          }}
                        >
                          <h4 className="disableMarginBlock">
                            BANCO DE TALENTOS
                          </h4>
                        </Grid>

                        <Grid
                          item
                          sx={{
                            display: { xs: "flex", sm: "flex", md: "none" },
                          }}
                        >
                          <h3 className="disableMarginBlock">
                            BANCO DE TALENTOS
                          </h3>
                        </Grid>

                        <Grid item>
                          <p className="disableMarginBlock">
                            Você tem RH interno ou quer fazer tudo sozinho
                            centralizando e automatizando todas as informações
                            dos seus processos seletivos. Então essa é a escolha
                            para a sua empresa.
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* List Content */}
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      zIndex="2"
                      rowGap={2}
                    >
                      <Grid
                        container
                        item
                        className={styles.itemListWhiteProduct}
                        columnGap={2}
                        alignItems="center"
                      >
                        <Grid item className={styles.iconProduct}>
                          <GroupsRoundedIcon
                            sx={{
                              height: { xs: "16px", sm: "16px", md: "24px" },
                              width: { xs: "16px", sm: "16px", md: "24px" },
                            }}
                          />
                        </Grid>
                        <Grid item xs sm lg>
                          <span className="paragrah_style">
                            Banco com mais de 165 mil talentos
                          </span>
                        </Grid>
                      </Grid>

                      <Grid item className={styles.itemListWhiteProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <ViewKanbanRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Gerenciador de Processos Seletivos (ATS)
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListWhiteProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <PsychologyRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Talentos com testes técnicos e fit cultural
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListWhiteProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <SearchRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Busque talentos de todo o Brasil na Connectabil
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs sm lg zIndex="2">
                      <Link to="/banco_talentos">
                        <Button
                          variant="contained"
                          color="whitePurple"
                          fullWidth={true}
                        >
                          Ver Planos
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <Box className={styles.backgroundStripesProductWhite}></Box>
                </Grid>
                {/* End of the First Product */}

                {/* Second Product Top Right */}
                <Grid item lg className={styles.productDisplay}>
                  <Grid container item rowGap={5} py={5} px={3}>
                    {/* Header Content */}
                    <Grid
                      container
                      item
                      className={styles.productHeaderContent}
                      lg={12}
                      zIndex="2"
                      rowGap={2}
                    >
                      <Grid
                        item
                        sx={{
                          display: { xs: "none", sm: "none", md: "flex" },
                        }}
                      >
                        <h4 className="disableMarginBlock">ASSESSORIA</h4>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          display: { xs: "flex", sm: "flex", md: "none" },
                        }}
                      >
                        <h3 className="disableMarginBlock">ASSESSORIA</h3>
                      </Grid>

                      <Grid item>
                        <p className="disableMarginBlock">
                          Você tem urgência com alguma vaga? Precisa de ajuda de
                          um especialista para fazer boa parte do processo
                          seletivo? Então esse produto é para sua empresa.
                        </p>
                      </Grid>
                    </Grid>

                    {/* List Content */}
                    <Grid container item lg={12} zIndex="2" rowGap={2}>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <HowToRegRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Contrate em até 14 dias
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <ViewKanbanRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Receba até 3 talentos alinhados com a sua vaga
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <SupervisedUserCircleRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Um especialista do time acompanhando o seu
                              processo
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <RestartAltRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Garantia de reposição, caso necessário
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs sm lg zIndex="2">
                      <Link to="/assessoria">
                        <Button
                          variant="contained"
                          color="whitePurple"
                          fullWidth={true}
                        >
                          Ver mais do Produto
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <Box className={styles.backgroundStripesProduct}></Box>
                </Grid>
                {/* End of the Second Product */}

                {/* Third Product Bottom Full - Tablet / Desktop */}
                <Grid
                  item
                  lg={12}
                  className={styles.productDisplay}
                  sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                >
                  <Grid
                    container
                    py={5}
                    px={3}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* Header Content */}
                    <Grid item lg={5} zIndex="2">
                      <Grid container rowGap={5}>
                        <Grid item lg={12}>
                          <Grid
                            container
                            rowGap={2}
                            className={styles.productHeaderContent}
                          >
                            <Grid item>
                              <h4 className="disableMarginBlock">
                                GERENCIADOR DE TALENTOS
                              </h4>
                            </Grid>
                            <Grid item>
                              <p className="disableMarginBlock">
                                Se o que você precisa é um gerenciador de
                                processos seletivos e de testes para aplicar nos
                                seus talentos. <br /> Então é esse produto que
                                você está procurando.
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={12}>
                          <Link to="/gerenciador_talentos">
                            <Button
                              variant="contained"
                              color="whitePurple"
                              fullWidth={true}
                            >
                              Ver mais do Produto
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* List Content */}
                    <Grid item lg={5} zIndex="2">
                      <Grid container rowGap={2} direction="column">
                        <Grid item className={styles.itemListProduct}>
                          <Grid container columnGap={2} alignItems="center">
                            <Grid item className={styles.iconProduct}>
                              <WebRounded />
                            </Grid>
                            <Grid item>
                              <span>Página de Carreiras Personalizada</span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={styles.itemListProduct}>
                          <Grid container columnGap={2} alignItems="center">
                            <Grid item className={styles.iconProduct}>
                              <StarRoundedIcon />
                            </Grid>
                            <Grid item>
                              <span>
                                Filtre talentos com os nossos testes técnicos
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={styles.itemListProduct}>
                          <Grid container columnGap={2} alignItems="center">
                            <Grid item className={styles.iconProduct}>
                              <PsychologyRoundedIcon />
                            </Grid>
                            <Grid item>
                              <span>Aplique e analise fit cultural</span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={styles.itemListProduct}>
                          <Grid container columnGap={2} alignItems="center">
                            <Grid item className={styles.iconProduct}>
                              <AttachMoneyRoundedIcon />
                            </Grid>
                            <Grid item>
                              <span>Vagas ilimitadas por R$79,90</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={styles.backgroundStripesProduct}></Box>
                </Grid>
                {/* End of Third Product */}

                {/* Third Product - Mobile */}
                <Grid
                  item
                  lg
                  className={styles.productDisplay}
                  sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                >
                  <Grid container item rowGap={5} py={5} px={3}>
                    {/* Header Content */}
                    <Grid
                      container
                      item
                      className={styles.productHeaderContent}
                      lg={12}
                      zIndex="2"
                      rowGap={2}
                    >
                      <Grid
                        item
                        sx={{
                          display: { xs: "flex", sm: "flex", md: "none" },
                        }}
                      >
                        <h3 className="disableMarginBlock">
                          GERENCIADOR DE TALENTOS
                        </h3>
                      </Grid>

                      <Grid item>
                        <p className="disableMarginBlock">
                          Se o que você precisa é um gerenciador de processos
                          seletivos e de testes para aplicar nos seus talentos.
                          Então é esse produto que você está procurando.
                        </p>
                      </Grid>
                    </Grid>

                    {/* List Content */}
                    <Grid container item lg={12} zIndex="2" rowGap={2}>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <WebRounded
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Página de Carreiras Persoanlizada
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <StarRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Utilize nossos testes técnicos para filtrar os
                              talentos
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <PsychologyRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Aplique e analise o fit cultural
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={styles.itemListProduct}>
                        <Grid container columnGap={2} alignItems="center">
                          <Grid item className={styles.iconProduct}>
                            <AttachMoneyRoundedIcon
                              sx={{
                                height: { xs: "16px", sm: "16px", md: "24px" },
                                width: { xs: "16px", sm: "16px", md: "24px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs sm lg>
                            <span className="paragraph_style">
                              Vagas ilimitadas por R%79,90
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs sm lg zIndex="2">
                      <Link to="/gerenciador_talentos">
                        <Button
                          variant="contained"
                          color="whitePurple"
                          fullWidth={true}
                        >
                          Ver mais do Produto
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <Box className={styles.backgroundStripesProduct}></Box>
                </Grid>
                {/* End of Third Product Mobile */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
      {/* End of Section Products */}
    </Container>
  );
}

export default Enterprises;
